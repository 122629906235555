import { Message } from '@/util/commonFuncs'
import { change_plank_dir } from '@/util/countPlankDir'
import { getPlateKnifeDiameter } from '@/util/dealPaibanData'
import defaultTagData from '@/util/defaultTagData'
import { getDeviceXDPI, mmToPx, pxTomm } from '@/util/exportFuncs'
import {
  SURPLUS_TAG_TEXT_OPTIONS,
  TEXT_OPTIONS,
  dealNotchDir,
  getEdgeValue,
  getPaibanTime,
  getSurplusSize,
  updateEdgeInfo,
} from '@/util/tag'
import { getGraghEdge } from '@/util/tag'
import { dealSurplusPlank } from '@/util/tag/pdf-draw'
import {
  dealTextStr,
  drawFormerEdge,
  drawLightSign,
  drawOpenDoor,
  drawPartHoleSlot,
  drawSpecialSign,
  drawTagText,
  fabricTable,
  handleOpenDoorDistance,
  initDrawData,
} from '@/util/tag/tag-draw'
import BwipJs from 'bwip-js'
import JsBarcode from 'jsbarcode'
import QRCode from 'qrcode'

import store from '../store'

let NC_SETTING = {}
let JX_CHECKED = false
let FIVE_SIX_DIR = false
let CONFIG = {}
// 是否需要旋转，自定义模板不包含这个属性
let isRotatePlank = false
const DPI = getDeviceXDPI()
const isOneCodeShouldToSvg = true
// 获取svg里面的所有的g标签
function getSvgAllGTag(svg) {
  let reg = /<g(\S|\s)*?<\/g>/g
  return svg.match(reg)
}

let configScale = 1
// 对尺寸进行缩放
function scaleSize(size) {
  return size * configScale
}

/**
 * @description 生成标签
 * @param template 模板数据
 * @param plank 大板信息
 * @param part 小板信息
 * @param plank_index 第几张大板
 * @param part_index 第几张小板 从0开始
 * @param total_part 小板总数
 * @param jx_checked 标签图镜像
 * @param five_six_dir 五六面钻放板基准
 * @param config 配置（放大倍数 scale, 画布颜色 backgroundColor）
 * @param nc_setting this.$store.state.ncSetting
 * @param showCutTag 是否显示裁剪余料标签
 */
function createTag(
  template,
  plank,
  part,
  plank_index,
  part_index,
  total_part,
  jx_checked,
  five_six_dir,
  config = {},
  nc_setting,
  currentPlank,
  collect,
  sort_value,
  isTemp,
  showCutTag,
  rotatePlank,
  label_num
) {
  // 参数太多了，再加参数，担心影响到其他地方使用
  const { roomNameMap: roomAndCabinetMap } = template
  // 存储自定义特殊标签信息的列表
  const specialMarkParList = []

  if (currentPlank) {
    const { size } = getGraghEdge(currentPlank, store.state.ncSetting)
    plank.plankSize = size
  }
  isRotatePlank = rotatePlank
  // 余料板件单独处理
  if (part.specialType == 'supplus' && template.type !== 'plank') {
    if (showCutTag) {
      return new_createLaveTag(
        template,
        part,
        plank,
        plank_index,
        total_part,
        config,
        nc_setting,
        collect,
        label_num
      )
    } else {
      return
    }
  }
  CONFIG = initConfig(config)
  NC_SETTING = JSON.parse(JSON.stringify(nc_setting)) || {}
  if (isTemp) {
    NC_SETTING.xyReverse = false
  }
  JX_CHECKED = jx_checked
  FIVE_SIX_DIR = five_six_dir

  let rectL = template.rectInfo.left
  let rectT = template.rectInfo.top
  let arrPI = getDeviceXDPI()
  // 初始化绘制数据
  initDrawData(CONFIG, NC_SETTING)
  let my_canvas = new fabric.StaticCanvas(CONFIG.canvas, {
    width: scaleSize(mmToPx(template.tag_width, arrPI)),
    height: scaleSize(mmToPx(template.tag_height, arrPI)),
    backgroundColor: CONFIG.backgroundColor,
  })
  console.log(
    'canvas_width',
    scaleSize(mmToPx(template.tag_width, arrPI)),
    'canvas_height',
    scaleSize(mmToPx(template.tag_height, arrPI))
  )

  // 计算缩放值-大板标签进行整体缩放
  const scaleW = template.tag_width / 60
  const scaleH = template.tag_height / 40
  let finalScale = 1
  if (scaleW === scaleH) {
    finalScale = scaleW
  } else if (scaleW > scaleH) {
    finalScale = scaleH
  } else {
    finalScale = scaleW
  }

  if (template.type === 'plank') {
    my_canvas.setZoom(finalScale)
  }
  // 双面加工大板标签添加翻面标识
  if (template.type === 'plank' && plank.ableToggle) {
    let circle = new fabric.Circle({
      top: 70 * CONFIG.scale,
      left: 130 * CONFIG.scale,
      radius: 15 * CONFIG.scale, //圆形半径
      fill: '#fff', //填充的颜色
      stroke: '#000', // 边框颜色
      strokeWidth: 2, // 边框大小
    })
    let circle_text = new fabric.Text('翻', {
      top: 78 * CONFIG.scale,
      left: 138.5 * CONFIG.scale,
      fontSize: 14 * CONFIG.scale,
    })
    my_canvas.add(circle)
    my_canvas.add(circle_text)
  }

  // 正反面孔
  let holes = part.holes
  // 正反面槽
  let slots = part.slots
  // 所有的孔槽
  const allHoleSlot = [...holes, ...slots]
  // 正面孔
  let frontH = holes.filter((v) => {
    return Number(v.side) === 1
  }).length
  // 背面孔
  let backH = holes.filter((v) => {
    return Number(v.side) === -1
  }).length
  // 侧孔
  let sholesL = part.sholes?.length
  // 正面槽
  let fSlots = part.slots.filter((v) => {
    return Number(v.side) === 1
  }).length
  // 背面槽
  let bSlots = part.slots?.filter((item) => {
    return Number(item.side) === -1
  }).length
  // 侧槽
  let sSlots = part.sslots?.length
  template.tem_data.forEach((item) => {
    let info = item.data
    let hasMoreThanAscii = false
    switch (item.type) {
      case 'holeSlotPicture':
        drawPartHoleSlot(
          my_canvas,
          part,
          rectL,
          rectT,
          info,
          {
            tempData: template.tem_data,
          },
          JX_CHECKED
        )
        break
      case 'FixedText': {
        const fixedText = info.text
        let fixedLeft = (info.left - rectL) * CONFIG.scale
        let fixedTop = (info.top - rectT) * CONFIG.scale
        const newInfo = JSON.parse(JSON.stringify(info))
        Object.assign(newInfo, { left: fixedLeft, top: fixedTop })
        drawTagText(my_canvas, fixedText, newInfo)
        break
      }
      case 'Graphics': {
        let points = info.points
        let newPoints = points.map((point) => {
          return {
            x: scaleSize(point.x - rectL),
            y: scaleSize(point.y - rectT),
          }
        })
        let Polygon = new fabric.Polygon(newPoints, {
          top: scaleSize(info.top - rectT),
          left: scaleSize(info.left - rectL),
          fill: info.fill,
          scaleX: info.scaleX,
          scaleY: info.scaleY,
          angle: info.angle,
          originX: 'center',
          originY: 'center',
          selectable: false,
        })
        my_canvas.add(Polygon)
        break
      }
      case 'specialMark':
        // 自定义特殊标记比率 为什么是0.8 因为特殊标签默认高宽为37  50 / 37 = 0.8
        const [custSpeciialTagratioX, custSpeciialTagratioY] = [
          scaleSize(0.8 * info.scaleX),
          scaleSize(0.8 * info.scaleY),
        ]
        const left = scaleSize(info.left - rectL)
        const top = scaleSize(info.top - rectT)
        const opacity = info.opacity
        // 组装参数 标签模板和柜柜跳转至此的情况都需要展示自定义特殊标签
        specialMarkParList.push({
          my_canvas,
          sign: part.specialFlag ?? part.specTagTemplate,
          template,
          opacity,
          sizeAuto: undefined,
          size: undefined,
          op: { left, top, custSpeciialTagratioX, custSpeciialTagratioY },
        })
        break
      // 绘制数据部分
      case 'DataSource': {
        let text = ''
        // 绘制大板
        if (template.type === 'plank') {
          // 获取到对应的text
          if (info.source_data === 'ncName') {
            text = plank.isGenNcSuffix
              ? plank.plankDrawMsg[info.source_data]
              : plank.plankDrawMsg[info.source_data].slice(
                  0,
                  plank.plankDrawMsg[info.source_data].lastIndexOf('.')
                )
          } else {
            text = plank.plankDrawMsg[info.source_data]
          }
          if (['size'].includes(info.source_data)) {
            const textArr = text.split('*')
            text = `${textArr[1]}*${textArr[0]}*${textArr[2]}`
          }
        } else {
          if (info.source_data === 'plankSize') {
            const textArr = plank[info.source_data].split('*')
            text = `${textArr[1]}*${textArr[0]}*${textArr[2]}`
          } else {
            text = countSourceData(
              part,
              plank_index,
              total_part,
              plank,
              template,
              my_canvas,
              info,
              label_num,
              NC_SETTING
            )
          }
        }
        if (!text) break

        let textC = new fabric.Text(text, {
          fontSize: scaleSize(info.fontSize),
        })

        const { text: str, fontSize: fts } = dealTextStr(
          text,
          info.fontSize,
          info.rectWidth ?? 80,
          info.rectHeight ?? 11.3,
          info.isAutoFontSize ?? true
        )

        let Itext = new fabric.Text(str, {
          fontSize: scaleSize(fts),
          selectable: false,
          fontWeight: info.fontWeight,
          left: scaleSize(info.left - rectL),
          top: scaleSize(info.top - rectT),
          fontFamily: info.fontFamily ?? '微软雅黑',
        })
        my_canvas.add(Itext)
        break
      }

      // 二维码
      case 'QRcode': {
        let url = ''
        if (template.type === 'plank') {
          // 绘制大板
          url = !(info.source_data === 'qrCode' && !plank.isGenNcSuffix)
            ? plank.plankDrawMsg[info.source_data]
            : plank.plankDrawMsg[info.source_data].slice(
                0,
                plank.plankDrawMsg[info.source_data].lastIndexOf('.')
              )
        } else {
          // 有反面标签
          if (info.source_data === 'plankNum') {
            if (frontH || sholesL || fSlots) url = part[info.source_data]
          } else if (info.source_data === 'oriPlankNum') {
            if (info.showCode) url = part[info.source_data]
            else if (
              frontH ||
              backH ||
              sholesL ||
              (slots && slots.length) ||
              sSlots
            )
              url = part.oriPlankNum
          } else if (info.source_data === 'ggid') {
            url = part['ggid']
          } else if (
            info.source_data === 'remarks' ||
            info.source_data === 'remark'
          ) {
            if (!!part[info.source_data]) {
              hasMoreThanAscii = [...part[info.source_data]].some(
                (char) => char.charCodeAt(0) > 127
              )
              let text = ''
              url = part[info.source_data]
              if (hasMoreThanAscii || part[info.source_data].length > 50) {
                url = ''
                text = '不支持该数据'
                let Itext = new fabric.Text(text, {
                  fontSize: scaleSize(info.fontSize),
                  selectable: false,
                  fontWeight: info.fontWeight,
                  left: scaleSize(info.left - rectL),
                  top: scaleSize(info.top - rectT),
                  fontFamily: info.fontFamily ?? '微软雅黑',
                  fontSize: 18,
                  rectWidth: info.rectWidth ?? 80,
                  rectHeight: info.rectHeight ?? 11.3,
                })
                my_canvas.add(Itext)
              }
            }
          } else if (info.source_data === 'ncName') {
            url = info.isQRcodeNCSuffix
              ? part.ncName
              : part.ncName.substring(0, part.ncName.lastIndexOf('.'))
          } else if (info.source_data === 'plankNumF') {
            if (backH || bSlots) url = part.oriPlankNumF
          } else if (info.source_data === 'size') {
            const { thick } = part
            let { height, width } = part['oRect']
            if (nc_setting.xyReverse) {
              ;[width, height] = [height, width]
            }
            url = `A${height}-B${width}-C${thick}`
          } else break
        }
        if (!url) break
        if (info.QRCodeFormat == 'DMCode') {
          let canvas = document.createElement('canvas')
          try {
            BwipJs.toCanvas(canvas, {
              bcid: 'datamatrix', // Barcode type
              text: url, // Text to encode
              scale: 1, // 3x scaling factor
              width: scaleSize(mmToPx(info.mWidth, arrPI)),
              height: scaleSize(mmToPx(info.mHeight, arrPI)),
            })
            const uri = canvas.toDataURL('image/png')
            fabric.Image.fromURL(
              uri,
              (img) => {
                img.set({
                  top: scaleSize(info.top - rectT),
                  left: scaleSize(info.left - rectL),
                  selectable: false,
                })
                my_canvas.add(img)
              },
              {
                scaleX: 0.35, // 缩放
                scaleY: 0.35, // 缩放
              }
            )
          } catch (e) {
            // `e` may be a string or Error object
            console.error(e)
          }
        } else {
          QRCode.toDataURL(url, {
            height: scaleSize(mmToPx(info.mHeight, arrPI)),
            width: scaleSize(mmToPx(info.mWidth, arrPI)),
            margin: 0,
            quality: 1,
            format: 'jpeg',
          }).then((url) => {
            fabric.Image.fromURL(url, (img) => {
              img.set({
                top: scaleSize(info.top - rectT),
                left: scaleSize(info.left - rectL),
                width: scaleSize(mmToPx(info.mWidth, arrPI)),
                height: scaleSize(mmToPx(info.mHeight, arrPI)),
                selectable: false,
              })
              my_canvas.add(img)
            })
          })
        }
        break
      }

      // 条形码
      case 'Onecode': {
        let OneCodeUrl = '',
          plankNumV = '',
          simplePlankNum = part.simplePlankNum
        const { front_file_identifier, opposite_file_identifier } =
          store.state.ncSetting
        if (info.source_data === 'plankNum') {
          if (frontH || sholesL || fSlots) {
            plankNumV = part.plankNum
            simplePlankNum += front_file_identifier ?? ''
            const flag = allHoleSlot.every((item) => item.side == -1)
            if (flag && allHoleSlot.length) {
              break
            }
          }
        } else if (info.source_data === 'plankNumF') {
          if (backH || bSlots || judgePtpNeedBackNum(sSlots, sholesL)) {
            simplePlankNum += opposite_file_identifier ?? ''
            plankNumV = currentPlank.oriPlankNumF
            const flag = allHoleSlot.every((item) => item.side == 1)
            if (
              flag &&
              !judgePtpNeedBackNum(sSlots, sholesL) &&
              allHoleSlot.length
            ) {
              break
            }
          }
        } else if (info.source_data === 'oriPlankNum') {
          if (info.showCode) {
            plankNumV = part.oriPlankNum
          } else {
            // 只有有孔槽才显示条码
            if (
              frontH ||
              backH ||
              sholesL ||
              (slots && slots.length) ||
              sSlots
            ) {
              plankNumV = part.oriPlankNum
            }
          }
        } else if (info.source_data === 'oriPlankNumNoMark') {
          if (info.showCode) {
            plankNumV = part.oriPlankNumNoMark
          } else {
            // 只有有孔槽才显示条码
            if (
              frontH ||
              backH ||
              sholesL ||
              (slots && slots.length) ||
              sSlots
            ) {
              plankNumV = part.oriPlankNumNoMark
            }
          }
        } else if (info.source_data === 'remarks') {
          plankNumV = part.remarks
        } else if (info.source_data === 'remark') {
          if (!!part.remark) {
            hasMoreThanAscii = [...part.remark].some(
              (char) => char.charCodeAt(0) > 127
            )
            let text = ''
            plankNumV = part.remark
            if (hasMoreThanAscii || part[info.source_data].length > 50) {
              plankNumV = ''
              text = '不支持该数据'
              let height = info.rectHeight ?? 11.3
              let width = info.rectWidth ?? 11.3
              let Itext = new fabric.Text(text, {
                fontSize: scaleSize(info.fontSize),
                selectable: false,
                fontWeight: info.fontWeight,
                left: scaleSize(info.left - rectL) + width / 2,
                top: scaleSize(info.top - rectT) + height / 2,
                fontFamily: info.fontFamily ?? '微软雅黑',
                fontSize: 18,
                rectWidth: width,
                rectHeight: height,
              })
              my_canvas.add(Itext)
            }
          }
        } else if (info.source_data === 'ncName') {
          plankNumV = info.isOnecodeNCSuffix
            ? part.ncName
            : part.ncName.substring(0, part.ncName.lastIndexOf('.'))
        } else break
        if (!plankNumV) break
        if (
          nc_setting.genSimpleLabelBarcode &&
          info.source_data !== 'ncName' &&
          info.source_data !== 'remark'
        )
          plankNumV = simplePlankNum
        let codeWidth = 0
        let OSvg = document.createElement('svg')
        let Ocanvas = document.createElement('canvas')
        // 标签数小于等于500时，使用svg
        try {
          JsBarcode(Ocanvas, plankNumV, {
            format: info.code_type.toUpperCase(),
            width: scaleSize(mmToPx(info.mWidth, arrPI)) / 120, // 生成条码时的魔法数字120，不要轻易改动
            height: scaleSize(mmToPx(info.mHeight, arrPI)),
            margin: 0,
            displayValue: false,
          })
          OneCodeUrl = Ocanvas.toDataURL('image/png', 1)
          if (!OneCodeUrl) break
          fabric.Image.fromURL(OneCodeUrl, (img) => {
            img.set({
              width: scaleSize(mmToPx(info.mWidth, arrPI)) * 1.2, // 宽度增大，以确保条码显示完整
              top: scaleSize(info.top - rectT),
              left: scaleSize(info.left - rectL),
              height: scaleSize(mmToPx(info.mHeight, arrPI) * 0.75),
              selectable: false,
            })
            /** 重新绘制文字 */
            const textW = scaleSize(mmToPx(info.mWidth, arrPI)) / 4
            const textH = scaleSize(mmToPx(info.mHeight, arrPI)) * 0.75
            plankNumV += ''
            const scaleX = info.mWidth / 32.54
            const scaleY = info.mHeight / 9.52
            const textScale = scaleX < scaleY ? scaleX : scaleY
            let Bartext = new fabric.Text(plankNumV, {
              fontSize: scaleSize(info.fontSize) * textScale,
              selectable: false,
              fontWeight: info.fontWeight === 'bold' ? 'bold' : '',
              top: scaleSize(info.top - rectT) + textH,
              left: scaleSize(info.left - rectL),
              fontFamily: info.fontFamily ?? '微软雅黑',
            })
            let OnecodeGroup = new fabric.Group([img, Bartext], {
              top: scaleSize(info.top - rectT),
              left: scaleSize(info.left - rectL),
            })
            OnecodeGroup.rotate(info.rotateDeg)
            my_canvas.add(OnecodeGroup)
          })
        } catch (err) {
          OneCodeUrl = ''
        }
        // }

        break
      }

      // 板件图形
      case 'Typography':
        drawEditParts(
          template,
          plank,
          part,
          part_index,
          info,
          rectL,
          rectT,
          my_canvas,
          sort_value
        )
        break

      case 'wardrobeNum': {
        const { field, roomSortWay } = info
        const { roomName, loc } = part
        const textValue = getPartFlag(
          { field, roomSortWay, part },
          { roomName, loc },
          roomAndCabinetMap
        )
        if (textValue === '') break
        let radius = scaleSize(info.radius)
        let circle = new fabric.Circle({
          radius: radius, //圆形半径
          fill: '#fff', //填充的颜色
          stroke: '#000', // 边框颜色
          strokeWidth: 1, // 边框大小
        })
        let numText = new fabric.IText(String(textValue), {
          fontSize: scaleSize(info.fontSize),
          fontWeight: info.fontWeight,
          originX: 'cneter',
          originY: 'cneter',
          left: radius,
          top: radius,
          fontFamily: info.fontFamily ?? '微软雅黑',
          roomSortWay: info.roomSortWay,
        })
        let cirGroup = new fabric.Group([circle, numText], {
          left: scaleSize(info.left - rectL),
          top: scaleSize(info.top - rectT),
        })
        my_canvas.add(cirGroup)
        break
      }

      case 'Table': {
        let tableData = []
        const { fontSize, top, left, source_data, fontFamily } = info
        if (
          info.source_data === 'extraParts' &&
          part.extraParts &&
          part.extraParts.length
        ) {
          tableData = part.extraParts.map((e) => [e.name, e.spec, e.num + ''])
        }
        fabricTable(
          my_canvas,
          false,
          tableData,
          scaleSize(fontSize),
          source_data,
          scaleSize(top - rectT),
          scaleSize(left - rectL),
          fontFamily
        )
        break
      }
    }
  })

  return new Promise((resolve) => {
    const tagEncodingFormat = NC_SETTING.labelImageFormatNew
    setTimeout(() => {
      my_canvas.renderAll()
      let base64
      setTimeout(() => {
        // 特殊标记
        const opacity = store.state.drawOpacity
        const sizeAuto = store.state.drawSizeAuto
        const size = store.state.drawSize
        // 判断模板中是否有自定义特殊标签
        if (specialMarkParList.length <= 0) {
          drawSpecialSign(
            my_canvas,
            part.specialFlag,
            template,
            opacity,
            sizeAuto,
            size
          )
        } else {
          specialMarkParList.forEach((param) => {
            const { my_canvas, sign, template, opacity, sizeAuto, size, op } =
              param
            drawSpecialSign(
              my_canvas,
              sign,
              template,
              opacity,
              sizeAuto,
              size,
              op
            )
          })
          specialMarkParList.length = 0
        }
        if (collect) {
          base64 = my_canvas.toDataURL({
            quality: 1,
            multiplier: 2,
            format: tagEncodingFormat,
          })
          collect.push(base64)
        }
        if (CONFIG.type === 'base64')
          resolve(
            base64 ||
              my_canvas.toDataURL({
                quality: 1,
                multiplier: 2,
                format: tagEncodingFormat,
              })
          )
        else if (CONFIG.type === 'o_size_base64')
          resolve(
            my_canvas.toDataURL({
              quality: 1,
              format: tagEncodingFormat,
            })
          )
        else resolve(my_canvas.toSVG())
      }, 0)
    }, 0)
  })
}

/** 初始化 config **/
function initConfig(config) {
  if (!config.scale) config.scale = 1
  if (!config.backgroundColor) config.backgroundColor = '#fff'
  if (!config.type) config.type = 'svg'
  if (!config.canvas) config.canvas = 'tag'
  configScale = config.scale
  return config
}

function countSourceData(
  part,
  plank_index,
  total_part,
  plank,
  template,
  my_canvas,
  info,
  label_num,
  NC_SETTING
) {
  let xyReverse = NC_SETTING.xyReverse
  if (!info.source_data) return ''
  let result = ''
  switch (info.source_data) {
    case 'plank_index':
      result = `第${plank_index}张大板`
      break
    case 'partsChildren':
      const hingeTypeList = ['铰链', '普通铰链', '一字底座铰链']
      if (part.partsChildren && part.partsChildren.length) {
        let isExist = hingeTypeList.includes(part.partsChildren[0].name)
        if (isExist) {
          let hingeString =
            part.partsChildren[0].matSpec +
            '_' +
            part.partsChildren[0].maskPartName
          result = hingeString
        }
      }
      break

    case 'lable_index':
      result = `第${label_num + 1}/${total_part}页`
      break
    case 'partName':
      if (sessionStorage.getItem('material_cache_data')) {
        result = part[info.source_data]
      } else {
        if (part[info.source_data]) {
          const res = part[info.source_data].split('_')
          // 避免A_B_的情况进入判断 但是类似于A_B_undefined还是正常走截取逻辑吧
          if (res.length <= 2 || (res.length === 3 && res[2] === '')) {
            result = res.join('_')
          } else {
            result = res.slice(2).join('_')
          }
        }
      }
      break
    case 'address':
    case 'orderNo':
      result = part[info.source_data] ? `${part[info.source_data]}` : ''
      break
    case 'customer_name':
      result = part[info.source_data] ?? part['customer'] ?? ''
      break
    case 'customer':
      result = part['customer'] ?? ''
      break
    case 'plankID':
      result = part[info.source_data] ? `${part[info.source_data]}#` : ''
      break
    case 'onlyPlankID':
      result = part['plankID'] ? part['plankID'] : ''
      break
    case 'plank_remarks':
      result = part[info.source_data] ? `${part[info.source_data]}` : ''
      break
    case 'frontProcess': {
      const frontArr = [...part.holes, ...part.slots]
      const frontRes = frontArr.some((item) => item.side == 1)
      if (frontRes) {
        result = '加工正面'
      }
      break
    }
    case 'reverseProcess': {
      const reversArr = [...part.holes, ...part.slots]
      const reversRes = reversArr.some((item) => item.side == -1)
      if (reversRes) {
        result = '加工反面'
      }
      break
    }
    case 'sideProcess': {
      const sideRes = part.sholes.length || part.sslots.length
      if (sideRes) {
        result = '加工侧面'
      }
      break
    }
    case 'oSize':
      if (info.sizeRestrict) {
        result = handleSizeNum(part[info.source_data])
      } else {
        const str = part[info.source_data]
          .split('*')
          .map((s) => Number(s))
          .join('*')
        result = str || ''
      }
      break
    case 'oSize_L':
      result = part.oSize
        .split('*')
        .slice(0, 2)
        .map((s) => Number(s))
        .join('*')
      break
    case 'oSize_W_L_T':
      const tempList = part.oSize.split('*').map((size) => Number(size))
      result = `${tempList[1]}*${tempList[0]}*${tempList[2]}`
      break
    case 'cutSize':
      if (info.sizeRestrict) {
        result = handleSizeNum(part[info.source_data])
      } else {
        result = part[info.source_data] || ''
      }
      break
    case 'doorSize':
      result = handleSizeNum(part[info.source_data])
      break
    case 'size':
      result = handleSizeNum(part[info.source_data])
      break
    case 'bigPlankID':
      result = plank_index + ''
      break
    case 'oriPlankNum':
      result = store.state.ncSetting.genSimpleLabelBarcode
        ? part.simplePlankNum
        : part[info.source_data]
      break
    case 'paibanTime':
      // let time = store.state.paibanTime
      //   ? store.state.paibanTime
      //   : new Date().getTime()
      // const date = new Date(time)
      // const Y = date.getFullYear() + '-'
      // const M =
      //   (date.getMonth() + 1 < 10
      //     ? '0' + (date.getMonth() + 1)
      //     : date.getMonth() + 1) + '-'
      // const D = date.getDate() + ' '
      // const h = date.getHours() + ':'
      // const m = date.getMinutes() + ':'
      // const s = date.getSeconds()
      // result = Y + M + D + h + m + s
      result = getPaibanTime()
      break
    case 'plankSignBox': {
      const sign = template.tem_data.find(
        (item) =>
          item.data.source_data == 'plankSign' &&
          item.data.item_id == info.item_id
      )
      if (!sign) break
      const { fontPt, fontSize, fontWeight, lineHeight, top, left } = sign.data
      let t = new fabric.Text(sign.data.text, {
        fontPt,
        fontSize: scaleSize(fontSize),
        fontWeight,
        lineHeight,
      })
      drawSignRect(
        {
          top,
          left,
          width: t.width,
          height: t.height,
          rectL: template.rectInfo.left,
          rectT: template.rectInfo.top,
        },
        my_canvas
      )
      break
    }

    case 'plankSign':
      result = '板件标注'
      break
    case 'sholeInfoF':
    case 'sslotInfoF': {
      const sourceData = info.source_data
      result =
        part[sourceData] && part[sourceData].length !== 2
          ? part[sourceData]
          : ''
      break
    }
    case 'process_label':
      // eslint-disable-next-line no-case-declarations
      const processMarkArr = []
      const remarksOptions = store.state.specialRemarks?.remarksOptions
      if (
        !remarksOptions ||
        Object.values(remarksOptions).every((item) => item)
      ) {
        part[info.source_data]?.auto_label?.forEach((item) => {
          processMarkArr.push(Object.values(item)[0])
        })
      } else {
        part[info.source_data]?.auto_label?.forEach((item) => {
          if (remarksOptions[Object.keys(item)[0]]) {
            processMarkArr.push(Object.values(item)[0])
          }
        })
      }
      part[info.source_data]?.custom_label?.forEach((item) => {
        processMarkArr.push(item)
      })
      result = processMarkArr.join('/')
      break
    case 'hsInfoTotal':
      result = part.hsInfo || '0孔0槽'
      break
    // 铰链孔位置
    case 'hingeHoleLocationData':
      if (part.holes.length !== 0) {
        let xList = []
        let yList = []
        let arr = []
        for (let item of part.holes) {
          // 判断为铰链孔
          if (item.symbol == 'HINGE') {
            // 判断当前孔的x，y的距离
            xList.push(Number(item.ocenter.x.toFixed(2))) //距y轴的距离
            yList.push(Number(item.ocenter.y.toFixed(2))) //距x轴的距离
          }
        }
        if (!xList.length && !yList.length) {
          result = ''
          break
        }
        // 判断当前铰链孔在与y轴平行的线上还是与x轴平行的线上
        let direction
        if (xList.length === 1 && yList.length === 1) {
          let jz = xList[0]
          let jy = part.oRect.width - xList[0]
          let js = part.oRect.height - yList[0]
          let jx = yList[0]
          let minSort = [jz, jy, js, jx].sort((a, b) => {
            return a - b
          })[0]
          if (minSort === jz || minSort === jy) {
            arr = yList
            direction = 'vertical'
          } else {
            arr = xList
            direction = 'transverse'
            arr = arr.map((item) => {
              return part.oRect.width - item
            })
          }
        } else {
          if (
            xList.length === [...new Set(xList)].length &&
            yList.length !== [...new Set(yList)].length
          ) {
            // 铰链孔在与x轴平行的线上
            arr = xList
            direction = 'transverse'
            arr = arr.map((item) => {
              return part.oRect.width - item
            })
          } else {
            // 铰链孔在与y轴平行的线上
            arr = yList
            direction = 'vertical'
          }
        }
        let closeEdge
        let firstEdge
        // 判断距边的上下左右
        if (direction === 'transverse') {
          if (part.oRect.height - yList[0] > yList[0]) {
            closeEdge = '距上' + yList[0].toFixed(2)
          } else {
            closeEdge = '距下' + (part.oRect.height - yList[0]).toFixed(2)
          }
          firstEdge = '距右'
        } else {
          if (part.oRect.width - xList[0] > xList[0]) {
            closeEdge = '距左' + xList[0].toFixed(2)
          } else {
            closeEdge = '距右' + (part.oRect.width - xList[0]).toFixed(2)
          }
          firstEdge = '距上'
        }
        arr = arr.sort((a, b) => {
          return a - b
        })
        arr = arr.map((item) => {
          return item.toFixed(2)
        })
        result = firstEdge + arr.join(' ') + ' ' + closeEdge
      } else {
        result = ''
      }
      break
    // 料单铰链位置
    case 'hingeInfo':
      // 对hingeInfo的数据四舍五入保留两位 双开距上，竖双距右
      // 可以通过openDir进行判断当前的开门方向
      if (!part.openDir) {
        result = part?.hingeInfo?.map((item) => item.toFixed(2)).join(' ') || ''
        break
      }
      result =
        (['左开', '右开'].includes(part.openDir) ? '距下' : '距左') +
          part?.hingeInfo?.map((item) => item.toFixed(2)).join(' ') || ''
      break
    default:
      result = part[info.source_data] || ''
      break
  }
  return String(result)
}

function handleSizeNum(str) {
  if (!str) return ''
  let s = str
  const a = str.split('*').map((r) => Number(r))
  s = a.map((item) => Number(item.toFixed(3)))

  return s.join('*')
}

function drawEditParts(
  template,
  plank,
  part,
  part_index,
  info,
  rectL,
  rectT,
  canvas,
  sort_value,
  cb,
  sixCb
) {
  if (!info.mWidth || !info.mHeight) {
    cb(null)
    return
  }

  let edgeInfo = countEdgeInfoByJXType(part)
  // 如果是大板标签，四条边的显示数值要发生变化
  edgeInfo = updateEdgeInfo(template, plank?.graghEdge) || edgeInfo
  let { topEdgeVal, bottomEdgeVal, leftEdgeVal, rightEdgeVal } =
    plank?.graghEdge || {}
  new Promise((resolve) => {
    // 获取文本宽高
    let widthS = [],
      heightS = []
    info.textItems?.forEach((item) => {
      const str = getEdgeValue(item.positionName, edgeInfo)
      if (!str) return
      let text = new fabric.Text(str, {
        positionName: item.positionName,
        fontSize: scaleSize(item.fontSize),
        fontWeight: item.fontWeight,
      })
      widthS.push(text.width)
      heightS.push(text.height)
    })
    resolve({
      width: Math.max.apply(null, widthS),
      height: Math.max.apply(null, heightS),
      widthS,
    })
  }).then((data) => {
    if (FIVE_SIX_DIR) {
      if (typeof sixCb === 'function') {
        sixCb(drawFiveSixDir(template, part, info))
      } else {
        canvas.add(drawFiveSixDir(template, part, info))
      }
    }

    // 根据文本宽高计算排版图偏移量
    // let textHeight = data.height > 18.08 ? 18.08 : data.height
    // let textWidth = data.width > 20 ? 20 : data.width
    let textHeight = data.height
    let textWidth = data.width

    let gW = mmToPx(info.mWidth, getDeviceXDPI())
    let gH = mmToPx(info.mHeight, getDeviceXDPI())

    // 减去字体的宽高，得到排版图的缩放比例
    let ScaleX =
      (gW - textWidth) /
      (NC_SETTING.xyReverse ? plank.plankHeight : plank.plankWidth)
    let ScaleY =
      (gH - textHeight) /
      (NC_SETTING.xyReverse ? plank.plankWidth : plank.plankHeight)
    let PlankScale = Math.min.apply(null, [ScaleX, ScaleY]) // 按比例取出小一点的，按照缩放比例绘制排版图
    let plankW = plank.plankWidth * PlankScale
    let plankH = plank.plankHeight * PlankScale
    let rect = null
    if (plank.surplusInfo && plank.surplusInfo.shape == 'lshape') {
      let paths = dealSurplusPlank(
        plank.surplusInfo,
        NC_SETTING,
        PlankScale,
        CONFIG.scale,
        JX_CHECKED
      )
      paths = paths.map((it) => ({
        x: mmToPx(it.x, DPI),
        y: mmToPx(it.y, DPI),
      }))
      rect = new fabric.Polygon(paths, {
        top: textHeight,
        left: textWidth,
        selectable: false,
        stroke: '#000',
        strokeWidth: 1.5,
        fill: '#fff',
      })
    } else {
      rect = new fabric.Rect({
        width: scaleSize(plankW),
        height: scaleSize(plankH),
        top: textHeight,
        left: textWidth, //从文字之后的位置计算，有一点点误差会重叠一点点，但是感觉不影响
        selectable: false,
        stroke: '#000',
        strokeWidth: 1.5,
        fill: '#fff',
      })
    }
    let parts_arr = []
    // 重新排序 先画异形板件
    let poyArr = [],
      rectArr = []
    plank.parts.forEach((item) => {
      if (item.path) {
        poyArr.push(item)
      } else {
        rectArr.push(item)
      }
    })
    let newPlankArr
    if (sort_value == 1) {
      newPlankArr = [...poyArr, ...rectArr]
    } else {
      newPlankArr = plank.parts
    }
    newPlankArr.forEach((item, index) => {
      // 异型
      if (item.path) {
        let curveHoles = []
        if (item.curveHoles) {
          curveHoles = item.curveHoles
            .filter((it) => it.deep >= item.thick)
            .map((it) => it.path)
        }
        let millInfo = []
        if (item.millInfo) {
          millInfo = item.millInfo
            .filter((it) => it.depth >= item.thick)
            .map((it) => it.shape)
        }
        const resPath = [...item.path, ...curveHoles, ...millInfo]
        resPath.forEach((pathItem, i) => {
          if (i === 0) {
            let pointArr = pathItem.map((item) => {
              return {
                x: scaleSize(item.x * PlankScale) + textWidth,
                y: scaleSize(item.y * PlankScale) + textHeight,
              }
            })
            let top = scaleSize(item.startY * PlankScale) + textHeight
            let left = scaleSize(item.startX * PlankScale) + textWidth
            let difShape = new fabric.Polygon(pointArr, {
              top: top,
              left: left,
              stroke: '#000',
              strokeWidth: scaleSize(0.7),
              selectable: false,
              // fill: '#fff',
              fill: 'transparent',
            })
            if (template.type !== 'plank') {
              if (sort_value == 1) {
                if (item.priority - 1 === part_index)
                  difShape.set({ fill: '#000' })
              } else {
                if (index === part_index) difShape.set({ fill: '#000' })
              }
            }
            parts_arr.push(difShape)
            // canvas.add(difShape);
          } else {
            let xs = [],
              ys = []
            let pointArr = pathItem.map((e) => {
              xs.push(e.x)
              ys.push(e.y)
              return {
                x: scaleSize(e.x * PlankScale) + textWidth,
                y: scaleSize(e.y * PlankScale) + textHeight,
              }
            })
            let top =
              Math.min.apply(null, ys) * PlankScale +
              (newPlankArr[index].startY * PlankScale +
                textHeight / CONFIG.scale)
            let left =
              Math.min.apply(null, xs) * PlankScale +
              (newPlankArr[index].startX * PlankScale +
                textWidth / CONFIG.scale)
            let difShape = new fabric.Polygon(pointArr, {
              top: scaleSize(top),
              left: scaleSize(left),
              stroke: '#000',
              strokeWidth: scaleSize(0.7),
              selectable: false,
              // fill: '#fff',
              fill: 'transparent',
            })
            parts_arr.push(difShape)

            // canvas.add(difShape);
          }
        })
      } else {
        // 普通
        let itemRect = new fabric.Rect({
          width: scaleSize(item.rect.width * PlankScale),
          height: scaleSize(item.rect.height * PlankScale),
          top: scaleSize(item.startY * PlankScale) + textHeight,
          left: scaleSize(item.startX * PlankScale) + textWidth,
          stroke: '#000',
          strokeWidth: scaleSize(0.7),
          fill: '#fff',
          // fill: 'transparent',

          selectable: false,
        })
        if (template.type !== 'plank') {
          if (sort_value == 1) {
            if (item.priority - 1 === part_index) itemRect.set({ fill: '#000' })
          } else {
            if (index === part_index) itemRect.set({ fill: '#000' })
          }
        }

        parts_arr.push(itemRect)
      }
    })
    let text_items = []
    let triangles = []
    info.textItems?.forEach((item) => {
      const finalText = getEdgeValue(item.positionName, edgeInfo)
      if (!finalText) return
      let text = new fabric.Text(finalText, {
        positionName: item.positionName,
        fontSize: scaleSize(item.fontSize),
        fontWeight: item.fontWeight,
        fontFamily: item.fontFamily ?? '微软雅黑',
      })
      let deviationW = scaleSize(plankW) + textWidth * 2
      let deviationH = scaleSize(plankH) + textHeight * 2
      function drawFrontEdge(top, left) {
        let triangle = new fabric.Triangle({
          top: NC_SETTING.xyReverse ? top + textWidth / 2 : top - 5,
          left: NC_SETTING.xyReverse ? left - textHeight / 2 : left,
          width: 10,
          height: 7,
          stroke: '#000000',
        })
        let rotate = NC_SETTING.xyReverse ? -90 : 0
        triangle.rotate(rotate)
        triangles.push(triangle)
      }
      switch (item.positionName) {
        case 'BE':
          text.set({
            top: deviationH - textHeight,
            left: deviationW / 2 - textWidth / 2,
          })
          break
        case 'TE':
          text.set({
            top: item.top,
            left: deviationW / 2 - textWidth / 2,
          })
          // if (!NC_SETTING.xyReverse && template.type !== 'plank') {
          //   drawFrontEdge(item.top, deviationW / 2 - textWidth / 2)
          // }
          break
        case 'RE':
          text.set({
            top: deviationH / 2 - textWidth / 2,
            left: deviationW - textWidth,
          })
          break
        case 'LE':
          text.set({
            top: deviationH / 2 - textWidth / 2,
            left: item.left,
          })
          // if (NC_SETTING.xyReverse && template.type !== 'plank') {
          //   drawFrontEdge(deviationH / 2 - textWidth / 2, item.left)
          // }
          break
      }
      // if (NC_SETTING.xyReverse || info.plankRotate) {
      //   text.rotate(90)
      // }
      text.set({
        position: item.positionName,
        opacity: info.showEdgeOff && +finalText <= 0 ? 0 : 1,
        canRotate: true,
      })
      text_items.push(text)
    })
    let groupTop = scaleSize(info.top - rectT)
    let groupLeft = scaleSize(info.left - rectL)

    if (NC_SETTING.xyReverse) {
      groupTop = scaleSize(info.top - rectT + (plankW - plankH) / 2)
      groupLeft = scaleSize(info.left - rectL - (plankW - plankH) / 2)
    }
    // 添加了前封边三角，保证排版图位置不变 需要扩大排版截图的范围
    // let offset = 10
    let offset = 0
    // 绘制开门方向
    const openDoor = drawOpenDoor(
      text_items,
      part,
      info,
      NC_SETTING,
      template.type
    )
    const signs = drawLightSign(text_items, part, NC_SETTING, template.type)
    const formerEdge = drawFormerEdge(
      text_items,
      part,
      NC_SETTING,
      template.type,
      configScale ?? 1
    )
    const elements = [
      rect,
      ...triangles,
      ...parts_arr,
      ...text_items,
      ...signs,
      ...openDoor.arr,
      formerEdge,
    ].filter((it) => it)
    let Group = new fabric.Group(elements, {
      left: groupLeft + openDoor.distance - offset / 2,
      top: groupTop - offset / 2,
    })
    //处理旋转
    rotateKlass(Group, info)
    // 处理开门方向在xy互换以及逆时针旋转时某些位置的不正确
    handleOpenDoorDistance(Group, info, openDoor.findText)
    if (typeof cb === 'function') {
      cb(Group)
    } else {
      canvas.add(Group)
    }
  })
}
function rotateKlass(klass, info) {
  if (isRotatePlank) {
    if (NC_SETTING.xyReverse) {
      klass.rotate(-180)
      rotateChildren(-180)
    } else {
      klass.rotate(-90)
      rotateChildren(90)
    }
  } else {
    if (NC_SETTING.xyReverse) {
      klass.rotate(-90)
      rotateChildren(90)
    }
  }

  if (info.rotateDeg || info.rotateDeg == 0) {
    if (NC_SETTING.xyReverse) {
      klass.rotate(info.rotateDeg - 90)
      rotateChildren(360 - info.rotateDeg + 90)
    } else {
      klass.rotate(info.rotateDeg)
      rotateChildren(360 - info.rotateDeg)
    }
  }

  function rotateChildren(a) {
    const ftext = klass.getObjects().filter((it) => it.canRotate)
    ftext.forEach((it) => it.rotate(a))
  }
}

function countEdgeInfoByJXType(part) {
  let edgeInfo = part.edgeInfo.split(/←|↓|→|↑/).filter((v) => v)
  // return edgeInfo
  if (!JX_CHECKED) return edgeInfo

  let xyReverse = NC_SETTING.xyReverse ? NC_SETTING.xyReverse : false
  if (xyReverse) {
    let copyEdgeV = JSON.parse(JSON.stringify(edgeInfo))[1]
    edgeInfo[1] = edgeInfo[3]
    edgeInfo[3] = copyEdgeV
  } else {
    let copyEdgeV = JSON.parse(JSON.stringify(edgeInfo))[0]
    edgeInfo[0] = edgeInfo[2]
    edgeInfo[2] = copyEdgeV
  }
  return edgeInfo
}

function drawFiveSixDir(template, part, info) {
  let output56FDrillSlotDir = 'default',
    output56FDrillLayoutDir = 'Vertical'
  if (
    NC_SETTING.hasOwnProperty('output56FDrillSlotDir') &&
    NC_SETTING.hasOwnProperty('output56FDrillLayoutDir')
  ) {
    output56FDrillSlotDir = NC_SETTING.output56FDrillSlotDir
    output56FDrillLayoutDir = NC_SETTING.output56FDrillLayoutDir
  }
  let spin_num = change_plank_dir(
    part,
    {
      ...NC_SETTING,
      output56FDrillSlotDir,
      output56FDrillLayoutDir,
      panelSize: NC_SETTING.panelSize,
      xyReverse: NC_SETTING.xyReverse || false,
    },
    info.plankWidth,
    info.plankHeight
  )
  let path = []
  // 获取标签宽高
  let width = scaleSize(mmToPx(template.tag_width, getDeviceXDPI()))
  let height = scaleSize(mmToPx(template.tag_height, getDeviceXDPI()))
  let min = 10,
    max = 40,
    mid = 20,
    top = 0,
    left = 0
  switch (spin_num) {
    case 0:
      path = [
        { x: 0, y: height - max },
        { x: min, y: height - max },
        { x: min, y: height - min },
        { x: max, y: height - min },
        { x: max, y: height },
        { x: 0, y: height },
        { x: 0, y: height - max },
      ]
      top = height - max
      left = 0
      break
    case 1:
      path = [
        { x: 0, y: 0 },
        { x: 0, y: max },
        { x: min, y: max },
        { x: min, y: min },
        { x: max, y: min },
        { x: max, y: 0 },
        { x: 0, y: 0 },
      ]
      top = 0
      left = 0
      break
    case 2:
      path = [
        { x: width - max, y: 0 },
        { x: width, y: 0 },
        { x: width, y: max },
        { x: width - min, y: max },
        { x: width - min, y: min },
        { x: width - max, y: min },
        { x: width - max, y: 0 },
      ]
      top = 0
      left = width - max
      break
    case 3:
      path = [
        { x: width - min, y: height - max },
        { x: width, y: height - max },
        { x: width, y: height },
        { x: width - max, y: height },
        { x: width - max, y: height - min },
        { x: width - min, y: height - min },
        { x: width - min, y: height - max },
      ]
      top = height - max
      left = width - max
      break
    default:
      path = [
        { x: 0, y: 0 },
        { x: 0, y: max },
        { x: max, y: max },
        { x: max, y: mid },
        { x: min, y: mid },
        { x: min, y: 0 },
        { x: 0, y: 0 },
      ]
      top = 0
      left = 0
      break
  }
  return new fabric.Polygon(path, {
    top: top,
    left: left,
    fill: '#000',
    selectable: false,
    opacity: 1,
    temName: 'spinDir',
  })
}

/**
 * @description 生成余料标签
 * @param template 模板数据
 * @param cut_part 当前小板
 * @param plank 大板信息
 * @param plank_index 第几张大板
 * @param total_part 总数
 * @param config 配置（放大倍数 scale, 画布颜色 backgroundColor）
 * @param nc_setting this.$store.state.ncSetting
 * @param collect 图片集合
 */
// 生成裁剪板件标签
async function new_createLaveTag(
  template,
  cut_part,
  plank,
  plank_index,
  total_part,
  config = {},
  nc_setting,
  collect,
  label_num
) {
  CONFIG = initConfig(config)
  NC_SETTING = nc_setting || {}
  // 获取余料边长信息
  cut_part.plankSize = getSurplusSize(
    cut_part.surplusPath[0],
    nc_setting,
    cut_part.stockKey
  ).str
  if (sessionStorage.getItem('thinkerx_material')) {
    cut_part.plankSize += `*1`
  } else {
    cut_part.plankSize += `*${plank.thick}`
  }
  let canvas_width = template.tag_width
  let canvas_height = template.tag_height
  let rectL = template.rectInfo.left
  let rectT = template.rectInfo.top
  let arrPI = getDeviceXDPI()
  // let plank_scale =
  //   (mmToPx(canvas_height, arrPI) * 2) /
  //   4 /
  //   (NC_SETTING.xyReverse ? plank.plankWidth : plank.plankHeight)
  // 绘制背景
  let my_canvas = new fabric.StaticCanvas(CONFIG.canvas, {
    width: mmToPx(canvas_width, arrPI) * CONFIG.scale,
    height: mmToPx(canvas_height, arrPI) * CONFIG.scale,
    backgroundColor: CONFIG.backgroundColor,
  })
  if (!template?.surplus_data) {
    // 设置为默认模板
    template.surplus_data = JSON.parse(defaultTagData).surplus_tem_data
  }
  await template.surplus_data.forEach(async (item) => {
    let info = item.data
    switch (item.type) {
      case 'FixedText': {
        const fixedText = info.text
        let fixedLeft = (info.left - rectL) * CONFIG.scale
        let fixedTop = (info.top - rectT) * CONFIG.scale
        const newInfo = JSON.parse(JSON.stringify(info))
        Object.assign(newInfo, { left: fixedLeft, top: fixedTop })
        drawTagText(my_canvas, fixedText, newInfo)
        break
      }
      // 数据来源需要修改
      case 'DataSource': {
        let text = ''
        let textArr = plank.plankSize.split('*')
        if (plank.surplusInfo?.isNotSurplus || !plank.surplusInfo) {
          textArr[0] = plank.plankHeight
          textArr[1] = plank.plankWidth
        }
        const infoStr = {
          oSize: cut_part.plankSize,
          oSize_L: cut_part.plankSize
            .split('*')
            .splice(0, cut_part.plankSize.split('*').length - 1)
            .join('*'),
          matCode: cut_part.matCode,
          bigPlankID: plank_index + '',
          plank_index: `第${plank_index}张大板`,
          lable_index: `第${label_num + 1}/${total_part}页`,
          paibanTime: getPaibanTime(),
          oriPlankNum: cut_part.oriPlankNum,
          plankSize: `${textArr[1]}*${textArr[0]}*${textArr[2]}`,
          texture: cut_part.texture,
          // 所在余料库
          branch_no: cut_part.branch_name || '-',
        }
        text = infoStr[info.source_data] ?? ''
        if (!text) break

        const { text: str, fontSize: fts } = dealTextStr(
          text,
          info.fontSize,
          info.rectWidth ?? 80,
          info.rectHeight ?? 11.3,
          info.isAutoFontSize ?? true
        )
        let Itext = new fabric.Text(str, {
          fontSize: scaleSize(fts),
          selectable: false,
          fontWeight: info.fontWeight,
          left: scaleSize(info.left - rectL),
          top: scaleSize(info.top - rectT),
          fontFamily: info.fontFamily ?? '微软雅黑',
        })
        my_canvas.add(Itext)
        break
      }
      case 'QRcode': {
        //需要判断当前获取的数值是余料入库码还是板件条码
        let url = ''
        if (info.source_data == 'oriPlankNum') {
          url = cut_part[info.source_data]
        } else if (info.source_data == 'restockingCode') {
          // url = `http://eggi.cn/?ggid=${plank.parts[0].ggid}`
          // 裁剪板件直接入库
          let uid = store.state.userInfo.id
          // page参数表示传递过去的数据是否是添加页面 1：直接添加，显示信息的页面，2：添加页面，需要用户手动输入信息
          let page = 1 //裁剪板件传过去都是直接入库，显示信息的页面
          // 根据suplusPath[0] 判断当前的余料形状
          let { shape, max_long, max_width, min_long, min_width } =
            getSurplusSize(
              cut_part.surplusPath[0],
              nc_setting,
              cut_part.stockKey
            ) //余料形状 1：L形 0：矩形
          let plankInfo = {
            uid,
            page,
            shape,
            matCode: cut_part.matCode,
            texture: cut_part.texture,
            thick: cut_part.thick,
            long: max_long,
            width: max_width,
            min_long,
            min_width,
          }
          url = `http://eggi.cn/?plankInfo=${JSON.stringify(plankInfo)}`
        }
        if (!url) break
        // 根据url绘制二维码
        if (info.QRCodeFormat == 'DMCode') {
          let canvas = document.createElement('canvas')
          try {
            BwipJs.toCanvas(canvas, {
              bcid: 'datamatrix', // Barcode type
              text: url, // Text to encode
              scale: 1, // 3x scaling factor
              width: scaleSize(mmToPx(info.mWidth, arrPI)),
              height: scaleSize(mmToPx(info.mHeight, arrPI)),
            })
            const uri = canvas.toDataURL('image/png')
            fabric.Image.fromURL(
              uri,
              (img) => {
                img.set({
                  top: scaleSize(info.top - rectT),
                  left: scaleSize(info.left - rectL),
                  selectable: false,
                })
                my_canvas.add(img)
              },
              {
                scaleX: 0.35, // 缩放
                scaleY: 0.35, // 缩放
              }
            )
          } catch (e) {
            // `e` may be a string or Error object
            console.error(e)
          }
        } else {
          await QRCode.toDataURL(url, {
            // height: scaleSize(mmToPx(info.mHeight, arrPI)),
            // width: scaleSize(mmToPx(info.mWidth, arrPI)),
            margin: 0,
            quality: 1,
            format: 'jpeg',
          }).then((url) => {
            fabric.Image.fromURL(url, (img) => {
              img.set({
                top: scaleSize(info.top - rectT),
                left: scaleSize(info.left - rectL),
                // width: scaleSize(mmToPx(info.mWidth, arrPI)),
                // height: scaleSize(mmToPx(info.mHeight, arrPI)),
                selectable: false,
              })
              img.set({
                scaleX: scaleSize(mmToPx(info.mWidth, arrPI)) / img.width,
                scaleY: scaleSize(mmToPx(info.mHeight, arrPI)) / img.height,
              })
              my_canvas.add(img)
            })
          })
        }
        break
      }
      case 'Marking': {
        let radius = scaleSize(info.radius)
        let circle = new fabric.Circle({
          radius,
          fill: '#fff',
          stroke: '#000',
          strokeWidth: 1,
        })
        let textFlag = new fabric.IText('余', {
          fontSize: scaleSize(info.fontSize),
          fontWeight: info.fontWeight,
          originX: 'cneter',
          originY: 'cneter',
          left: radius,
          top: radius,
          fontFamily: info.fontFamily ?? '微软雅黑',
        })
        let group = new fabric.Group([circle, textFlag], {
          left: scaleSize(info.left - rectL),
          top: scaleSize(info.top - rectT),
        })
        my_canvas.add(group)
        break
      }
      case 'Typography': {
        let ScaleXCopy = mmToPx(info.mWidth) / plank.plankWidth
        let ScaleYCopy = mmToPx(info.mHeight) / plank.plankHeight
        if (NC_SETTING.xyReverse) {
          ScaleXCopy = mmToPx(info.mWidth) / plank.plankHeight
          ScaleYCopy = mmToPx(info.mHeight) / plank.plankWidth
        }
        let plank_scale = Math.min.apply(null, [ScaleXCopy, ScaleYCopy])
        let oTop = info.top - rectT
        let oLeft = info.left - rectL
        let parts_arr = []
        plank.parts.forEach((part) => {
          if (part.path) {
            let curveHoles = []
            if (part.curveHoles) {
              curveHoles = part.curveHoles
                .filter((it) => it.deep >= part.thick)
                .map((it) => it.path)
            }
            let millInfos = []
            if (part.millInfo) {
              millInfos = part.millInfo
                .filter((it) => it.depth >= part.thick)
                .map((it) => it.shape)
            }
            const resPath = [...part.path, ...curveHoles, ...millInfos]
            resPath.forEach((path, idx) => {
              let dif_shape
              if (idx === 0) {
                let point_arr = path.map((v) => {
                  return {
                    x: (v.x * plank_scale + oLeft) * CONFIG.scale,
                    y: (v.y * plank_scale + oTop) * CONFIG.scale,
                  }
                })
                dif_shape = new fabric.Polygon(point_arr, {
                  top: (part.startY * plank_scale + oTop) * CONFIG.scale,
                  left: (part.startX * plank_scale + oLeft) * CONFIG.scale,
                  stroke: '#000',
                  strokeWidth: 1.5,
                  selectable: false,
                  fill: part.plankNum == cut_part.plankNum ? '#000' : '#fff',
                })
              } else {
                let point_arr = path.map((v) => {
                  return {
                    x:
                      ((part.startX + v.x) * plank_scale + oLeft) *
                      CONFIG.scale,
                    y:
                      ((part.startY + v.y) * plank_scale + oTop) * CONFIG.scale,
                  }
                })
                dif_shape = new fabric.Polygon(point_arr, {
                  stroke: '#000',
                  strokeWidth: 1.5,
                  selectable: false,
                  fill: '#fff',
                })
              }
              parts_arr.push(dif_shape)
            })
          } else {
            let rect_item = new fabric.Rect({
              width: part.rect.width * plank_scale * CONFIG.scale,
              height: part.rect.height * plank_scale * CONFIG.scale,
              top: (part.startY * plank_scale + oTop) * CONFIG.scale,
              left: (part.startX * plank_scale + oLeft) * CONFIG.scale,
              stroke: '#000',
              strokeWidth: 1.5,
              fill: part.plankNum == cut_part.plankNum ? '#000' : '#fff',
              selectable: false,
            })
            parts_arr.push(rect_item)
          }
        })

        let rect
        if (plank.surplusInfo && plank.surplusInfo.shape == 'lshape') {
          let paths = dealSurplusPlank(
            plank.surplusInfo,
            NC_SETTING,
            plank_scale,
            CONFIG.scale,
            JX_CHECKED
          )
          paths = paths.map((it) => ({
            x: mmToPx(it.x, DPI),
            y: mmToPx(it.y, DPI),
          }))
          rect = new fabric.Polygon(paths, {
            top: oTop * CONFIG.scale,
            left: oLeft * CONFIG.scale,
            selectable: false,
            stroke: '#000',
            strokeWidth: 1.5,
            fill: '#fff',
          })
        } else {
          rect = new fabric.Rect({
            width: plank.plankWidth * plank_scale * CONFIG.scale,
            height: plank.plankHeight * plank_scale * CONFIG.scale,
            top: oTop * CONFIG.scale,
            left: oLeft * CONFIG.scale,
            selectable: false,
            stroke: '#000',
            strokeWidth: 1.5,
            fill: '#fff',
          })
        }
        let groupTop = oTop
        let groupLeft = oLeft
        if (NC_SETTING.xyReverse) {
          groupTop =
            oTop +
            (Math.abs(plank.plankHeight - plank.plankWidth) * plank_scale) / 2
          groupLeft =
            oLeft -
            (Math.abs(plank.plankHeight - plank.plankWidth) * plank_scale) / 2
        }
        // 按照比例处理
        let group = new fabric.Group([rect].concat(parts_arr), {
          width: plank.plankWidth * plank_scale * CONFIG.scale,
          height: plank.plankHeight * plank_scale * CONFIG.scale,
          top: groupTop * CONFIG.scale,
          left: groupLeft * CONFIG.scale,
        })
        rotateKlass(group, info)
        // if (NC_SETTING.xyReverse) group.rotate(-90)
        // if (isRotatePlank) group.rotate(-90)
        // if (NC_SETTING.xyReverse && isRotatePlank) group.rotate(-180)
        my_canvas.add(group)
        group.sendToBack()
        break
      }
      case 'Onecode': {
        let OneCodeUrl = '',
          plankNumV = '',
          simplePlankNum = cut_part.simplePlankNum
        if (info.source_data === 'oriPlankNum') {
          plankNumV = cut_part.oriPlankNum
        } else break
        if (!plankNumV) break
        if (
          nc_setting.genSimpleLabelBarcode &&
          info.source_data !== 'ncName' &&
          info.source_data !== 'remark'
        )
          plankNumV = simplePlankNum
        let codeWidth = 0
        let OSvg = document.createElement('svg')
        let Ocanvas = document.createElement('canvas')
        // 标签数小于等于500时，使用svg
        try {
          JsBarcode(Ocanvas, plankNumV, {
            format: info.code_type.toUpperCase(),
            width: scaleSize(mmToPx(info.mWidth, arrPI)) / 120, // 生成条码时的魔法数字120，不要轻易改动
            height: scaleSize(mmToPx(info.mHeight, arrPI)),
            margin: 0,
            displayValue: false,
          })
          OneCodeUrl = Ocanvas.toDataURL('image/png', 1)
          if (!OneCodeUrl) break
          fabric.Image.fromURL(OneCodeUrl, (img) => {
            img.set({
              width: scaleSize(mmToPx(info.mWidth, arrPI)) * 1.2, // 宽度增大，以确保条码显示完整
              top: scaleSize(info.top - rectT),
              left: scaleSize(info.left - rectL),
              height: scaleSize(mmToPx(info.mHeight, arrPI) * 0.75),
              selectable: false,
            })
            /** 重新绘制文字 */
            const textW = scaleSize(mmToPx(info.mWidth, arrPI)) / 4
            const textH = scaleSize(mmToPx(info.mHeight, arrPI)) * 0.75
            plankNumV += ''
            const scaleX = info.mWidth / 32.54
            const scaleY = info.mHeight / 9.52
            const textScale = scaleX < scaleY ? scaleX : scaleY
            let Bartext = new fabric.Text(plankNumV, {
              fontSize: scaleSize(info.fontSize) * textScale,
              selectable: false,
              fontWeight: info.fontWeight === 'bold' ? 'bold' : '',
              top: scaleSize(info.top - rectT) + textH,
              left: scaleSize(info.left - rectL),
              fontFamily: info.fontFamily ?? '微软雅黑',
            })
            let OnecodeGroup = new fabric.Group([img, Bartext], {
              top: scaleSize(info.top - rectT),
              left: scaleSize(info.left - rectL),
            })
            OnecodeGroup.rotate(info.rotateDeg)
            my_canvas.add(OnecodeGroup)
          })
        } catch (err) {
          OneCodeUrl = ''
        }
        break
      }
      case 'SurplusSize': {
        // 获取余料各边位置
        let notchDirArr = dealNotchDir(cut_part.surplusPath[0])
        let cutPartDrawPoint = {
          left: (info.left - rectL) * CONFIG.scale,
          top: (info.top - rectT) * CONFIG.scale,
        }
        let cutSizeArr = cut_part.cutSize?.split('*')
        let scaleX = mmToPx(info.mWidth, arrPI) / cutSizeArr[1]
        let scaleY = mmToPx(info.mHeight, arrPI) / cutSizeArr[0]
        let SurplusSizeScale = Math.min.apply(null, [scaleX, scaleY])
        const drawPoint = cut_part.surplusPath[0].map((it) => ({
          x: (it.x * SurplusSizeScale + cutPartDrawPoint.left) * CONFIG.scale,
          y: (it.y * SurplusSizeScale + cutPartDrawPoint.top) * CONFIG.scale,
        }))
        let cut_shape = new fabric.Polygon(drawPoint, {
          top: cutPartDrawPoint.top,
          left: cutPartDrawPoint.left,
          stroke: '#000',
          strokeWidth: 1.5,
          selectable: false,
          fill: '#fff',
        })
        if (isRotatePlank) {
          cut_shape.rotate(-90)
          let arr = []
          const len = notchDirArr.length
          notchDirArr.forEach((it, idx) => {
            let d = JSON.parse(JSON.stringify(it))
            d.text = notchDirArr[(len + idx - 1) % len].text
            arr.push(d)
          })
          notchDirArr = arr
        }
        const diameterR = getPlateKnifeDiameter(
          cut_part.stockKey,
          store.state.ncSetting
        )
        let { width, height } = cut_shape
        let { top: startY, left: startX } = cutPartDrawPoint
        notchDirArr.forEach((it) => {
          let { text, dir } = it
          text = parseInt(Number(text).toFixed(2)) - diameterR
          let top, left
          switch (dir) {
            case 'left':
              top = startY + height / 2
              left = startX - 18
              break
            case 'bottom':
              top = startY + height
              left = startX + width / 2
              break
            case 'right':
              top = startY + height / 2
              left = startX + width
              break
            case 'top':
              top = startY - 10
              left = startX + width / 2
              break
            case 'width':
              top = startY + height
              left = startX + width / 2
              break
            case 'height':
              top = startY + height / 2
              left = startX + width
              break
          }
          const textKlass = new fabric.Text(`${text}`, {
            left,
            top,
            fontSize: info.textItems[0].fontSize * CONFIG.scale,
            fontFamily: info.textItems[0].fontFamily,
            fontWeight: info.textItems[0].fontWeight,
          })
          const span = document.createElement('span')
          span.innerText = text
          span.style.fontSize = '12px'
          document.body.append(span)
          const { offsetHeight, offsetWidth } = span
          document.body.removeChild(span)
          if (dir == 'top') {
            textKlass.set({
              top: textKlass.top - offsetHeight,
            })
          }
          if (dir == 'left') {
            textKlass.set({
              left: textKlass.left - offsetWidth,
            })
          }
          if (dir == 'top' || dir == 'bottom') {
            textKlass.set({
              left: textKlass.left - offsetWidth / 2,
            })
          }
          if (dir == 'right') {
            textKlass.set({
              top: textKlass.top - offsetHeight / 2,
            })
          }
          my_canvas.add(textKlass)
        })
        my_canvas.add(cut_shape)
        cut_shape.sendToBack() // 放到最底层
        break
      }
    }
  })
  return new Promise((resolve) => {
    setTimeout(() => {
      my_canvas.renderAll()
      let base64
      if (collect) {
        base64 = my_canvas.toDataURL({ quality: 1, multiplier: 2 })
        collect.push(base64)
      }
      setTimeout(() => {
        if (CONFIG.type === 'base64')
          resolve(base64 || my_canvas.toDataURL({ quality: 1, multiplier: 2 }))
        else if (CONFIG.type === 'o_size_base64')
          resolve(
            my_canvas.toDataURL({
              quality: 1,
              format: NC_SETTING.labelImageFormat || 'jpg',
            })
          )
        else resolve(my_canvas.toSVG())
      }, 0)
    }, 0)
  })
}

/**
 * @description 生成余料标签
 * @param template 模板数据
 * @param plank 大板信息
 * @param plank_index 第几张大板
 * @param config 配置（放大倍数 scale, 画布颜色 backgroundColor）
 * @param nc_setting this.$store.state.ncSetting
 */
// 生成废料板件
function createLaveTag(
  template,
  plank,
  plank_index,
  config = {},
  nc_setting,
  collect,
  rotatePlank,
  total_part,
  part_page,
  label_num
) {
  // 可以获取到之前存进去的surplus_data
  isRotatePlank = rotatePlank
  CONFIG = initConfig(config)
  NC_SETTING = nc_setting || {}
  let rectL = template.rectInfo.left
  let rectT = template.rectInfo.top
  let canvas_width = template.tag_width
  let canvas_height = template.tag_height
  let arrPI = getDeviceXDPI()
  // let plank_scale =
  //   (mmToPx(canvas_height, arrPI) * 2) /
  //   3 /
  //   (NC_SETTING.xyReverse ? plank.plankWidth : plank.plankHeight)
  // 绘制背景
  let my_canvas = new fabric.StaticCanvas(CONFIG.canvas, {
    width: scaleSize(mmToPx(canvas_width, arrPI)),
    height: scaleSize(mmToPx(canvas_height, arrPI)),
    backgroundColor: CONFIG.backgroundColor,
  })
  if (!template?.surplus_data) {
    // 设置为默认模板
    template.surplus_data = JSON.parse(defaultTagData).surplus_tem_data
  }
  template.surplus_data.forEach((item) => {
    let info = item.data
    let ScaleXCopy = mmToPx(info.mWidth) / plank.plankWidth
    let ScaleYCopy = mmToPx(info.mHeight) / plank.plankHeight
    if (NC_SETTING.xyReverse) {
      ScaleXCopy = mmToPx(info.mWidth) / plank.plankHeight
      ScaleYCopy = mmToPx(info.mHeight) / plank.plankWidth
    }
    let plank_scale = Math.min.apply(null, [ScaleXCopy, ScaleYCopy])
    switch (item.type) {
      case 'FixedText': {
        const fixedText = info.text
        let fixedLeft = (info.left - rectL) * CONFIG.scale
        let fixedTop = (info.top - rectT) * CONFIG.scale
        const newInfo = JSON.parse(JSON.stringify(info))
        Object.assign(newInfo, { left: fixedLeft, top: fixedTop })
        drawTagText(my_canvas, fixedText, newInfo)
        break
      }
      case 'DataSource': {
        let text = ''
        // 处理DataSouce数据
        const infoStr = {
          oSize: '',
          oSize_L: '',
          matCode: plank.matCode,
          bigPlankID: plank_index + '',
          plank_index: `第${plank_index}张大板`,
          lable_index: `第${label_num + 1}/${total_part}页`,
          paibanTime: getPaibanTime(),
          oriPlankNum: '',
          plankSize: `${plank.plankHeight}*${plank.plankWidth}*${plank.thick}`,
          texture: plank.texture,
          branch_no: '-',
        }
        text = infoStr[info.source_data] ?? ''
        if (!text) break

        const { text: str, fontSize: fts } = dealTextStr(
          text,
          info.fontSize,
          info.rectWidth ?? 80,
          info.rectHeight ?? 11.3,
          info.isAutoFontSize ?? true
        )
        let Itext = new fabric.Text(str, {
          fontSize: scaleSize(fts),
          selectable: false,
          fontWeight: info.fontWeight,
          left: scaleSize(info.left - rectL),
          top: scaleSize(info.top - rectT),
          fontFamily: info.fontFamily ?? '微软雅黑',
        })
        my_canvas.add(Itext)
        break
      }
      // 废料标签的二维码
      case 'QRcode': {
        let url = ''
        if (info.source_data == 'oriPlankNum') {
          break
        } else if (info.source_data == 'restockingCode') {
          let uid = store.state.userInfo.id
          let page = 2
          let shape = 2
          let plankInfo = {
            uid,
            page,
            shape,
            matCode: plank.matCode,
            texture: plank.texture,
            thick: plank.thick,
          }
          url = `http://eggi.cn/?plankInfo=${JSON.stringify(plankInfo)}`
        }
        if (!url) break
        // 根据url绘制二维码
        if (info.QRCodeFormat == 'DMCode') {
          let canvas = document.createElement('canvas')
          try {
            BwipJs.toCanvas(canvas, {
              bcid: 'datamatrix', // Barcode type
              text: url, // Text to encode
              scale: 1, // 3x scaling factor
              width: scaleSize(mmToPx(info.mWidth, arrPI)),
              height: scaleSize(mmToPx(info.mHeight, arrPI)),
            })
            const uri = canvas.toDataURL('image/png')
            fabric.Image.fromURL(
              uri,
              (img) => {
                img.set({
                  top: scaleSize(info.top - rectT),
                  left: scaleSize(info.left - rectL),
                  selectable: false,
                })
                my_canvas.add(img)
              },
              {
                scaleX: 0.35, // 缩放
                scaleY: 0.35, // 缩放
              }
            )
          } catch (e) {
            // `e` may be a string or Error object
            console.error(e)
          }
        } else {
          QRCode.toDataURL(url, {
            height: scaleSize(mmToPx(info.mHeight, arrPI)),
            width: scaleSize(mmToPx(info.mWidth, arrPI)),
            margin: 0,
            quality: 1,
            format: 'jpeg',
          }).then((url) => {
            fabric.Image.fromURL(url, (img) => {
              img.set({
                top: scaleSize(info.top - rectT),
                left: scaleSize(info.left - rectL),
                width: scaleSize(mmToPx(info.mWidth, arrPI)),
                height: scaleSize(mmToPx(info.mHeight, arrPI)),
                selectable: false,
              })
              my_canvas.add(img)
            })
          })
        }

        break
      }
      case 'Marking': {
        let radius = scaleSize(info.radius)
        let circle = new fabric.Circle({
          radius,
          fill: '#fff',
          stroke: '#000',
          strokeWidth: 1,
        })
        let textFlag = new fabric.IText('余', {
          fontSize: scaleSize(info.fontSize),
          fontWeight: info.fontWeight,
          originX: 'cneter',
          originY: 'cneter',
          left: radius,
          top: radius,
          fontFamily: info.fontFamily ?? '微软雅黑',
        })
        let group = new fabric.Group([circle, textFlag], {
          left: scaleSize(info.left - rectL),
          top: scaleSize(info.top - rectT),
        })
        my_canvas.add(group)

        break
      }
      case 'Typography': {
        let oTop = info.top - rectT
        let oLeft = info.left - rectL
        // mmToPx(canvas_width, arrPI) -
        //   (NC_SETTING.xyReverse ? plank.plankHeight : plank.plankWidth) *
        //     plank_scale -
        //   12
        let parts = plank.parts
        let parts_arr = []
        parts.forEach((part) => {
          if (part.path) {
            let point_arr = part.path[0].map((v) => {
              return {
                x: scaleSize(v.x * plank_scale + oLeft),
                y: scaleSize(v.y * plank_scale + oTop),
              }
            })
            let dif_shape = new fabric.Polygon(point_arr, {
              top: scaleSize(part.startY * plank_scale + oTop),
              left: scaleSize(part.startX * plank_scale + oLeft),
              stroke: '#000',
              strokeWidth: 1,
              selectable: false,
              fill: '#fff',
            })
            parts_arr.push(dif_shape)
          } else {
            let rect_item = new fabric.Rect({
              width: scaleSize(part.rect.width * plank_scale),
              height: scaleSize(part.rect.height * plank_scale),
              top: scaleSize(part.startY * plank_scale + oTop),
              left: scaleSize(part.startX * plank_scale + oLeft),
              stroke: '#fff',
              strokeWidth: 1,
              fill: '#fff',
              selectable: false,
            })
            parts_arr.push(rect_item)
          }
        })

        let rect = new fabric.Rect({
          width: scaleSize(plank.plankWidth * plank_scale),
          height: scaleSize(plank.plankHeight * plank_scale),
          top: scaleSize(oTop),
          left: scaleSize(oLeft),
          selectable: false,
          stroke: '#000',
          strokeWidth: 1,
          fill: '#000',
        })
        let groupTop = oTop
        let groupLeft = oLeft
        if (NC_SETTING.xyReverse) {
          groupTop =
            oTop +
            (Math.abs(plank.plankHeight - plank.plankWidth) * plank_scale) / 2
          groupLeft =
            oLeft -
            (Math.abs(plank.plankHeight - plank.plankWidth) * plank_scale) / 2
        }
        let group = new fabric.Group([rect].concat(parts_arr), {
          width: scaleSize(plank.plankWidth * plank_scale),
          height: scaleSize(plank.plankHeight * plank_scale),
          top: scaleSize(groupTop),
          left: scaleSize(groupLeft),
        })
        rotateKlass(group, info)
        // if (NC_SETTING.xyReverse) group.rotate(-90)
        // if (isRotatePlank) group.rotate(-90)
        // if (NC_SETTING.xyReverse && isRotatePlank) group.rotate(-180)
        my_canvas.add(group)
        group.sendToBack()
        break
      }
      case 'Onecode': {
        break
      }
      case 'SurplusSize': {
        break
      }
    }
  })

  return new Promise((resolve) => {
    setTimeout(() => {
      my_canvas.renderAll()
      let base64
      if (collect) {
        base64 = my_canvas.toDataURL({ quality: 1, multiplier: 2 })
        collect.push(base64)
      }
      setTimeout(() => {
        if (CONFIG.type === 'base64')
          resolve(base64 || my_canvas.toDataURL({ quality: 1, multiplier: 2 }))
        else if (CONFIG.type === 'o_size_base64')
          resolve(
            my_canvas.toDataURL({
              quality: 1,
              format: NC_SETTING.labelImageFormat || 'jpg',
            })
          )
        else resolve(my_canvas.toSVG())
      }, 0)
    }, 0)
  })
}

function drawSignRect(current, my_canvas) {
  let { top, left, width, height, rectL, rectT } = current
  let dpi = getDeviceXDPI()
  const k = pxTomm(60, dpi)

  let rect = new fabric.Rect({
    left: scaleSize(left - rectL) - k / 2,
    top: scaleSize(top - rectT) - k / 2,
    width: width + k,
    stroke: '#000',
    strokeWidth: 2,
    fill: 'transparent',
    height: height + k,
  })
  my_canvas.add(rect)
}

// 根据svg的条形码信息，把条形码绘制到canvas上面
function drawBarCodeSvgInCanvas(
  svgXml,
  CONFIG = {
    scale: 1,
    left: 0,
    // y,
    top: 0,
  },
  cb
) {
  // let svgXml = document.querySelector('#barcode')
  // 获取所有g
  let gList = svgXml.querySelectorAll('g')
  let rectList = []
  if (gList.length > 1) {
    // 在ean13里面有多个g元素，每个g元素都有自己的translate。它下面的rect元素的x属性都是基于g来计算的
    gList.forEach((g) => {
      let reg = /translate\((\d+\.?\d*), 0\)/
      let translateX = g.outerHTML.match(reg)[1]
      // g下面的rect
      let gRectList = g.querySelectorAll('rect')
      gRectList.forEach((item) => {
        item.setAttribute('data-translateX', translateX)
      })
    })
  }
  rectList = svgXml.querySelectorAll('g > rect')
  let firstLeft, finalleft, finalWidth
  const rectArr = []
  rectList.forEach((item, index) => {
    let reg =
      /<rect x="(\d+\.?\d*)" y="(\d+\.?\d*)" width="(\d+\.?\d*)" height="(\d+\.?\d*)"/
    let result = item.outerHTML.match(reg)
    let rectPos = {}
    if (result.length) {
      let translateX = +(item.dataset.translateX || 0)
      rectPos.x = +result[1] + translateX
      rectPos.y = +result[2]
      rectPos.width = +result[3]
      rectPos.height = +result[4]
    }
    let left = CONFIG.left + rectPos.x
    var rect = new fabric.Rect({
      width: rectPos.width * 0.9, // 让每条的宽度小一点，在不影响使用的情况下，避免打印的时候多条线连接在一起
      height: CONFIG.height || rectPos.height,
      stroke: '#000',
      fill: '#000',
      top: CONFIG.top + rectPos.y,
      left: left,
      strokeWidth: 0,
      selectable: false,
    })
    if (index === 0) {
      firstLeft = left
    } else if (index === rectList.length - 1) {
      finalleft = left
      finalWidth = rectPos.width
    }
    rectArr.push(rect)
  })
  if (typeof cb === 'function') {
    cb(rectArr)
  }
  return finalleft - firstLeft + finalWidth
}
/**
 *
 * @param {*} sSlots 侧孔
 * @param {*} sholesL 侧槽
 */
export function judgePtpNeedBackNum(sSlots, sholesL) {
  const process_setting_id = store.state.ncSetting.process_setting_id
  const processInfo = store.state.proLineList.find(
    (item) => process_setting_id === item.id
  )
  let isNeedBackNum = false
  // 如果是ptp并且有侧孔或者侧槽时
  if (processInfo && Object.keys(processInfo).length) {
    const isPtp = processInfo.data?.ptpHoleDrill
    if (isPtp && (sSlots || sholesL)) {
      isNeedBackNum = true
    }
  }
  return isNeedBackNum
}

// 房间名字与字母的映射关系
const roomNameToChartMap = (roomList) => {
  if (!roomList.length) return

  const result = {}
  // 房间名字与字母的映射关系
  // const roomNameMap = new Map()
  // // 柜体名和数字的映射关系
  // const cabinetNumMap = new Map()
  // 统计房间出现次数,26个房间以内时对应A-Z，如果超过26个房间，则对应AA-AZ,然后是BA-AZ,CA-CZ,DA-DZ
  const generateChart = () => {
    let firstChart = ''
    let secondChartCode = 65
    let secondChart = ''
    let result = ''
    return () => {
      if (secondChartCode === 'Z'.charCodeAt()) {
        result = firstChart + String.fromCharCode(secondChartCode)
        if (!firstChart) {
          firstChart = 'A'
        } else {
          firstChart = String.fromCharCode(firstChart.charCodeAt() + 1)
        }
        secondChartCode = 65
        secondChart = String.fromCharCode(secondChartCode)
      } else {
        secondChart = String.fromCharCode(secondChartCode)
        secondChartCode++
        result = firstChart + secondChart
      }
      return result
    }
  }

  const genChartFn = generateChart()

  // 生成递增的数字序号
  const generateOrderNum = () => {
    let orderNum = 1
    return () => orderNum++
  }
  const generateOrderNumFn = generateOrderNum()

  // 生成房间号
  const generateValue = () => {
    return [genChartFn(), generateOrderNumFn()]
  }
  roomList.forEach((item) => {
    let { roomName, loc, orderNo } = item.part
    // let roomName = item.part.roomName
    // let loc = item.part.loc
    // if (!orderNo) return
    if (!orderNo) {
      orderNo = ''
    }
    if (!result[orderNo]) {
      result[orderNo] = [new Map(), new Map()]
    }
    if (!result[orderNo][0].has(roomName)) {
      result[orderNo][0].set(roomName, generateValue())
    }
    if (!result[orderNo][1].has(roomName)) {
      result[orderNo][1].set(roomName, [loc])
    } else {
      const current = result[orderNo][1].get(roomName)
      // 判断当前柜体是已存在
      const isExist = current.find((it) => it === loc)
      // 不存在，将该柜体加入，并且初始化柜体号
      if (!isExist) {
        current.push(loc)
      }
    }
  })
  return result
}

// 根据计算好的房间编号，柜体编号，返回对应的标签标记
const getPartFlag = (
  { field, roomSortWay, part },
  { roomName, loc },
  roomAndCabinetMap
) => {
  let { orderNo } = part
  let textValue = ''
  if (field === 'wardrobeNum') {
    textValue = part[field] ?? '面'
  } else if (field === 'isFrontPlank') {
    if (!part[field]) {
      textValue = ''
    } else {
      //面板标记
      textValue = '面'
    }
  } else {
    if (!roomAndCabinetMap) return
    // 解构出房间编号，柜体编号
    // const { roomNameMap, cabinetNumMap } = roomAndCabinetMap
    // 然后将数组第一项删除
    let roomChart = ''
    orderNo = orderNo || ''
    if (roomSortWay === 'letters') {
      roomChart = roomAndCabinetMap[orderNo][0].get(roomName)[0]
    } else {
      roomChart = roomAndCabinetMap[orderNo][0].get(roomName)[1]
    }
    const cabinet = roomAndCabinetMap[orderNo][1].get(roomName)
    // 找出对应的柜体编号
    const cabinetNumIndex = cabinet.findIndex((item) => item === loc)
    textValue = `${roomChart}-${cabinetNumIndex + 1}`
  }

  return textValue
}

export { createTag, createLaveTag, roomNameToChartMap, getPartFlag }
